@import '../../common-scss/_variables.scss';

.center {
    text-align: center;
    padding-top: 20px;
    position: relative;

    .line {
        border: 2px solid #178706;
        width: 40px;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: 8px;
    }
}

.wrapper.subtabs {
    margin: 30px 0 0 0;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    h2 {
        color: var(--color-000-fff);
        font-family: Roboto Condensed;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .overflow {
        width: 100vw; // Carousel full width
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;

        .row {
            display: flex;
            align-items: center;

            width: 100%;
            overflow: hidden;

            .left {
                padding: 0 0 0 36px;
            }

            .default {
                padding: 0;
            }

            .transform {
                display: flex;
                //margin: 0 15px;

                width: inherit;
                overflow-x: scroll; /* Hide horizontal scrollbar */
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }

                .default {
                    gap: 15px;
                }
                .small {
                    gap: 6px;
                }

                .list {
                    display: flex;
                    flex-flow: row;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .navigation {
            display: none;
        }
    }

    button {
        cursor: pointer;
        height: 40px;
        width: 40px;
        color: var(--color-000-fff);

        border-radius: 30px;
        background-color: transparent;

        &:disabled {
            display: none;
            transition: background-color 0.3s ease;
        }
    }
}

@media only screen and (min-width: $mobileBreakpoint) {
    .wrapper {
        .container {
            // For Review Carousel
            .tablet {
                display: flex;
                align-items: center;
                width: 100%;
            }
            // For Default Carousel
            .navigation {
                margin-bottom: 15px;
                svg {
                    stroke: var(--color-000-fff);
                }
            }

            .start {
                justify-content: space-between;
            }
            .end {
                justify-content: flex-end;
            }
            .default {
                justify-content: space-between;
            }
        }
        .overflow {
            width: auto;
        }
    }
}

@media only screen and (min-width: $desktopBreakpoint) {
    .wrapper.subtabs {
        margin: 24px 0 0 0;
    }
    .wrapper {
        h2 {
            font-size: 26px;
            margin: 0;
        }

        .container {
            // For Default Carousel
            .navigation {
                display: flex;
                align-items: center;
                width: 100%;

                margin-bottom: 5px;
            }
        }
    }
}
